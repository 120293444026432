.slide-from-right {
  animation-name: slide-from-right;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes slide-from-right {
  0% {
    width: 0;
  }

  100% {
    width: 24rem;
  }
}

.slide-to-right {
  animation-name: slide-to-right;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes slide-to-right {
  0% {
    width: 24rem;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}

//soundwave animation
.soundwave {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@for $i from 1 through 4 {
  #Line_#{$i} {
    animation: pulse 0.7s infinite;
    animation-delay: $i * 0.12s;
  }
}

#Line_5 {
  animation: pulse 0.7s infinite;
  animation-delay: 0.12;
}

#Line_6 {
  animation: pulse 0.7s infinite;
  animation-delay: 0.12;
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
