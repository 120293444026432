@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 100;
  src: local("Gontserrat"), url("Gontserrat-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 200;
  src: local("Gontserrat"), url("Gontserrat-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Gontserrat"), url("Gontserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Gontserrat"), url("Gontserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Gontserrat"), url("Gontserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 600;
  src: local("Gontserrat"), url("Gontserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Gontserrat"), url("Gontserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gontserrat";
  font-style: normal;
  font-weight: 800;
  src: local("Gontserrat"), url("Gontserrat-ExtraBold.ttf") format("truetype");
}
