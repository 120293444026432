@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/colors.scss";
@import "./assets/utils.scss";

@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 p,
  b {
    color: $neutral-heavy;
  }
  input[type="checkbox"],
  input[type="radio"],
  input[type="select"] {
    @apply accent-interactive;

    &.controlled {
      //This is the lightest accent-color that will not flip the mark to black, DON'T CHANGE :) Bjorn & Karl
      accent-color: #8e8e8e;
    }
  }
  .warning {
    color: $warning;
  }

  .error {
    @apply text-error text-caption;
  }

  .success {
    color: $success;
  }

  td {
    &.error {
      @apply bg-red-600 text-white;
    }
  }

  .disabled {
    @apply text-neutral-light cursor-not-allowed border-transparent;
  }

  button,
  [type="submit"] {
    @apply rounded-xl font-semibold w-min whitespace-nowrap ease-out transition-all duration-75 py-3 px-4 text-white bg-interactive hover:bg-interactive-hover;

    &.secondary {
      @apply bg-interactive-background hover:bg-interactive-hover hover:border-interactive-hover text-interactive border border-interactive-trim hover:text-white;
      &:disabled {
        @apply cursor-not-allowed bg-white text-neutral-light border border-neutral-light hover:bg-transparent;
      }
      &.warning {
        @apply text-warning bg-white hover:bg-warning  border-warning hover:text-white;
      }
    }
    &.info {
      @apply bg-info text-white hover:bg-info hover:border-info hover:text-white;
    }
    &.warning {
      @apply bg-warning text-white hover:bg-warning hover:border-warning hover:text-white;
    }
    &.delete {
      @apply border-error-trim text-error bg-error-light hover:bg-error-heavy hover:border-error-heavy hover:text-white;
    }

    &.neutral {
      @apply text-neutral bg-neutral-trim hover:bg-neutral-light;
    }

    &.icon {
      @apply border-2 py-1.5 px-2 stroke-current h-10 w-10;
    }

    &.small {
      @apply py-2 px-3 text-base h-10;
    }

    &.wide {
      @apply w-44;
    }

    &:disabled {
      @apply bg-neutral-light hover:bg-neutral-light cursor-not-allowed;
    }
  }
}

.faded {
  overflow: hidden;
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.bg-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  left: 0;
  right: 0;
  z-index: -1;
  display: block;
  //background-image: url("/julbild.jpg");
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(20px);
  -webkit-filter: blur(20px);
}

body {
  margin: 0;
  font-family: Gontserrat !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
